import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import NavbarComponent from "../Components/Navbar";
import RegistrationPage from "../Components/RegistrationPage";
import RegistrationPageResponsive from "../Components/RegistrationPageResponsive";
import Success from "./Success";

const CompleteRegistration = () => {
  const { search } = useLocation();

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/register.css" />
      </Helmet>
      <NavbarComponent />
      <RegistrationPage />
      <RegistrationPageResponsive />
    </>
  );
};

export default CompleteRegistration;
